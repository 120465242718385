import React from 'react';
import styled from '@emotion/styled';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import { DownArrow, StrongUnderline } from '../SVG';
import { devices, Text, WhiteButtonLink, Spacing } from '../common';
import { RichTextContent } from '../StyledComponents';

type Props = {
  order: number;
  header: string;
  content: string;
  targetUrl?: string;
  hidden?: boolean;
};

export default function TextAccordion({
  order,
  header,
  content,
  targetUrl,
  hidden,
}: Props) {
  return (
    <RootContainer order={order}>
      <Container hidden={hidden}>
        <AccordionSummary
          expandIcon={<DownArrow />}
          aria-controls={'panel1a-content'}
          id={'panel1a-header'}
        >
          <Text variant={'title-3'}>{header}</Text>
        </AccordionSummary>
        <AccordionContainer>
          <AccordionDetails>
            <StrongUnderline width={256} translateY={-20} translateX={-28} />
            <RichTextContent>{content}</RichTextContent>

            {targetUrl && (
              <>
                <Spacing axis="y" amount="medium" />
                <WhiteButtonLink href={targetUrl}>
                  Asiantuntijat
                </WhiteButtonLink>
              </>
            )}
          </AccordionDetails>
        </AccordionContainer>
      </Container>
    </RootContainer>
  );
}

const RootContainer = styled.div<{ order: number }>`
  width: 100%;
  & .MuiAccordion-root {
    background-color: ${(props) =>
      props.order % 2 === 0 ? `#C4DEF9` : `#7FADED`};
    border-radius: 0;
    box-shadow: none;
  }
  & .MuiAccordionDetails-root {
    align-items: center;
    flex-direction: column;
  }
  & .MuiAccordionSummary-content {
    justify-content: center;
  }
`;

const AccordionContainer = styled.div`
  overflow-x: auto;
  @media ${devices.mobileL} {
    .MuiAccordionDetails-root {
      padding: ${(props) => props.theme.spacing.default} 0px;
    }
  }
`;

const Container = styled(Accordion)<{ hidden?: boolean }>`
  display: ${(props) => (props.hidden ? 'none' : 'block')};
  padding: 20px;
  background-color: #0b2341;
`;
